<template>
  <div class="p-20px text-16px leading-relaxed">
    <pre v-if="webConfig.about">{{ webConfig.about }}</pre>
    <template v-else>
      <p class="font-bold">用数据驱动运营。</p>
      <p class="font-bold">让每个决策都有数据支撑。</p>

    </template>
    <div class="version">
      <div>当前程序版本：v{{ VERSION }}</div>
      <div>
        构建日期：
        {{ dFormat(BUID_DATE, 'YYYY-MM-DD HH:mm:ss') }}

      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

/**
 * <p>****************************************************************************</p>

 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2023年07月10日</li>
 * <li>@author     : ____′↘夏悸</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
  name: "About",
  computed: {
    ...mapState(['webConfig']),
  },
}
</script>

<style scoped lang="less">
.version {
  margin-top: 36px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  width: 100%;
  text-align: center;
  font-size: 12px;
}
</style>
